<template>
  <referentiel-list
  :listTitle="'Liste des régions Rome'"
  :referentielName="referentielName"
  :tableFields="tableFields"
  :emptyReferentielItem="emptyReferentielItem"
  >
  <template #formContent="{ handleInput, editableItem }">
    <div class="row">
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.libelle" @input="handleInput" type="text" class="form-control" id="libelle" name="libelle" placeholder="Libellé">
            <label for="name">Libellé</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-sm-12">
        <ValidationProvider rules="required" v-slot="{ errors }">
          <div class="form-floating mb-3">
            <input :value="editableItem.code" @input="handleInput" type="text" class="form-control" id="code" name="code" placeholder="Code">
            <label for="code">Code</label>
            <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </template>

  </referentiel-list>
</template>

<script>
import ReferentielList from '../../../components/common/referentiel/ReferentielList.vue'
export default {
  components: {
    ReferentielList
  },
  computed: {
    referentielName () {
      return 'region'
    },
    emptyReferentielItem () {
      return {
        libelle: null,
        code: null
      }
    },
    tableFields () {
      return [
        '#',
        'index',
        { key: 'libelle', label: 'Libellé' },
        { key: 'code', label: 'Code' },
        'actions',
      ]
    },
  }

}
</script>

<style>

</style>